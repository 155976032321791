import { gql } from "@apollo/client";

export const TASK_LIST_GQL_QUERY = gql`
  query($meetingId: ObjectId!) {
    tasks(query: { meetingId: { _id: $meetingId } }, sortBy: _ID_DESC) {
      _id
      assignees {
        _id
        displayName
      }
      progress
      status {
        _id
        name
      }
      title
      deadline
    }
  }
`;
