import React, { ReactChild } from "react";
import { Card, Col, Divider, Row, Typography } from "antd";

const { Text, Paragraph, Title } = Typography;

type LayoutProps = {
  children: ReactChild | ReactChild[];
};
export const AuthLayout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <Row style={{ marginTop: "80px" }} justify="space-around" align="middle">
      <Col span={8}>
        <Title style={{ minHeight: "92px" }}>
          {/* <Typing> */}
          Keep control
          <br /> of your
          {/* <Typing.Delay ms={1000} /> */}
          <Text className="primary-text-color"> meetings.</Text>
          {/* </Typing> */}
        </Title>
        <Divider />
        <Paragraph>
          Manage your meetings better by keeping notes, creating tasks,
          assigning tasks to participants and tracking results.
        </Paragraph>
        <img
          style={{ width: "100%", height: "280px", marginTop: "40px" }}
          src="/svgs/undraw_completed_tasks.svg"
          alt="meeting tasks undraw illustration"
        />
      </Col>
      <Col span={8}>
        <Card>{children}</Card>
      </Col>
    </Row>
  );
};
