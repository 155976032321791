import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Button, Form, message } from "antd";
import React, { useState } from "react";
import {
  ValidatorContextOptions,
  ValidatorProvider,
} from "react-class-validator";
import { Participant } from "../../../models/participant";
import { AddParticipantsForm } from "../forms/add-participants";
import { UPDATE_MEETING_GQL_MUTATION } from "../mutations/meeting-gql";
import { MEETING_DETAILS_GQL_QUERY } from "../queries/meeting-gql";

const validatorOptions: ValidatorContextOptions = {
  onErrorMessage: (error): string[] => {
    // custom error message handling (localization, etc)
    return [];
  },
};

interface Props {
  meetingId: string;
  participants: Participant[];
}
export const AddParticipantModal = (props: Props) => {
  const { meetingId, participants } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [updateOneMeeting, { error, loading }] = useMutation(
    UPDATE_MEETING_GQL_MUTATION,
    {
      refetchQueries: [
        {
          query: MEETING_DETAILS_GQL_QUERY,
          variables: { _id: meetingId },
        },
      ],
    }
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: Participant[]) => {
    updateOneMeeting({
      variables: { _id: meetingId, data: { participants: values } },
    })
      .then(() => {
        form.resetFields();
        setVisible(false);
        message.success("Participant(s) added to meeting.");
      })
      .catch((e) => {
        console.log(error);
        console.log(e);
      });
  };

  return (
    <ValidatorProvider options={validatorOptions}>
      <Button
        type="link"
        size="small"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        Add Participant
      </Button>
      <Modal
        title="Add participants"
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        okText="Save participants"
        onCancel={handleCancel}
        style={{ top: 20 }}
        bodyStyle={{ minHeight: "60vh" }}
      >
        <AddParticipantsForm
          form={form}
          onFinish={onFinish}
          participants={participants}
        />
      </Modal>
    </ValidatorProvider>
  );
};
