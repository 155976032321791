import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import { routes } from "./routes";
import { RouteProvider } from "./shared/contexts/route-context";
import { CustomApolloProvider } from "./dependencies/graphql/context";
import { REALM_APP_ID } from "./config/environment";
import { RealmAppProvider } from "./dependencies/realm/context";

ReactDOM.render(
  <React.StrictMode>
    <RealmAppProvider appId={REALM_APP_ID}>
      <CustomApolloProvider>
        <BrowserRouter>
          <RouteProvider routes={routes} />
        </BrowserRouter>
      </CustomApolloProvider>
    </RealmAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
