import React from "react";
import { Layout } from "antd";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";

type Props = {
  route?: string;
};
export const App: React.FC<RouteConfigComponentProps<Props>> = ({ route }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {renderRoutes(route?.routes)}
    </Layout>
  );
};
