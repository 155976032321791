import React from "react";
import { Badge, Col, Row, Skeleton, Typography } from "antd";
import { TaskCard } from "./task-card";
import { Task } from "../../../models/task";
import { Status } from "../../../models/status";
import { Participant } from "../../../models/participant";
import { CreateTask } from "./create-task";
import { useQuery } from "@apollo/client";
import { TASK_LIST_GQL_QUERY } from "../queries/tasks-gql";
import { STATUS_LIST_GQL_QUERY } from "../queries/status-gql";
const { Title } = Typography;

interface Props {
  meetingId: string;
  participants: Participant[];
}
export const MeetingBoard = (props: Props) => {
  const { meetingId, participants } = props;

  const { loading: statusLoading, data: statusData } = useQuery<{
    statuses: Status[];
  }>(STATUS_LIST_GQL_QUERY);
  const { loading: taskLoading, data: taskData } = useQuery<{ tasks: Task[] }>(
    TASK_LIST_GQL_QUERY,
    {
      variables: { meetingId: meetingId },
    }
  );

  return (
    <Skeleton loading={statusLoading}>
      <Row gutter={16}>
        {statusData?.statuses.map((status) => {
          const statusTasks = taskData?.tasks.filter(
            (x) => x.status._id === status._id
          );
          return (
            <Col key={`status-${status._id}`} className="gutter-row" span={6}>
              <Title level={5}>
                {status.name}{" "}
                <Badge
                  count={statusTasks?.length}
                  style={{ backgroundColor: "#b9b9b9" }}
                />
              </Title>
              {status.default && (
                <CreateTask meetingId={meetingId} defaultStatus={status} />
              )}
              <Skeleton loading={taskLoading}>
                {statusTasks?.map((task) => (
                  <TaskCard
                    key={`task-${task._id}`}
                    data={task}
                    statuses={statusData?.statuses}
                    meetingId={meetingId}
                    participants={participants}
                    progress={status.progress}
                  />
                ))}
              </Skeleton>
            </Col>
          );
        })}
      </Row>
    </Skeleton>
  );
};
