import { gql } from "@apollo/client";

export const CREATE_MEETING_GQL_MUTATION = gql`
  mutation addMeeting($data: MeetingInsertInput!) {
    insertOneMeeting(data: $data) {
      _id
      createdBy
      time
      title
      createdAt
      datetime
      duration {
        hours
        minutes
      }
      meetinglinks {
        provider
        link
      }
      participants {
        _id
        displayName
        phoneNumber
        email
      }
    }
  }
`;

export const UPDATE_MEETING_GQL_MUTATION = gql`
  mutation updateMeeting($_id: ObjectId!, $data: MeetingUpdateInput!) {
    updateOneMeeting(query: { _id: $_id }, set: $data) {
      _id
      createdBy
      time
      title
      createdAt
      datetime
      duration {
        hours
        minutes
      }
      meetinglinks {
        provider
        link
      }
      participants {
        _id
        displayName
        phoneNumber
        email
      }
    }
  }
`;
