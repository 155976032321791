import { gql } from "@apollo/client";

export const USER_LIST_SEARCH_GQL_QUERY = gql`
  query($displayName: String!) {
    userdata(
      query: { OR: [{ displayName: $displayName }, { email: $displayName }] }
      limit: 100
      sortBy: _ID_DESC
    ) {
      _id
      displayName
      email
      userId
    }
  }
`;

export const USER_DATA_GQL_QUERY = gql`
  query($_id: ObjectId!) {
    userdatum(query: { userId: $_id }) {
      _id
      displayName
      email
      userId
    }
  }
`;
