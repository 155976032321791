import React from "react";
import { RouteConfig } from "react-router-config";
import { App } from "./App";
import { AuthGuard } from "./modules/auth/guards/auth-guard";
import { ChangePasswordPage } from "./modules/auth/pages/change-password";
import { LoginPage } from "./modules/auth/pages/login";
import { ResetPasswordPage } from "./modules/auth/pages/reset-password";
import { SignupPage } from "./modules/auth/pages/signup";
import { DashboardPage } from "./modules/dashboard/pages";
import { MeetingPage } from "./modules/meeting/pages";
import { MeetingDetailsPage } from "./modules/meeting/pages/details";
import { BlankLayout } from "./shared/layouts/blank-layout";
import { PageLayout } from "./shared/layouts/page-layout";
import { TasksPage } from "./modules/tasks/pages";
import { MeetingCreatePage } from "./modules/meeting/pages/create";

type PageRouteConfig = {
  name?: string;
  routes?: PageRouteConfig[];
} & RouteConfig;

export const routes: PageRouteConfig[] = [
  {
    component: App,
    routes: [
      {
        name: "Home",
        path: "/",
        exact: true,
        component: () => (
          <AuthGuard
            children={
              <PageLayout>
                <DashboardPage />
              </PageLayout>
            }
          />
        ),
      },
      {
        path: "/login",
        exact: true,
        component: () => (
          <BlankLayout>
            <LoginPage />
          </BlankLayout>
        ),
      },
      {
        path: "/signup",
        exact: true,
        component: () => (
          <BlankLayout>
            <SignupPage />
          </BlankLayout>
        ),
      },
      {
        path: "/reset-password",
        exact: true,
        component: () => (
          <BlankLayout>
            <ResetPasswordPage />
          </BlankLayout>
        ),
      },
      {
        path: "/change-password",
        exact: true,
        component: () => (
          <BlankLayout>
            <ChangePasswordPage />
          </BlankLayout>
        ),
      },
      {
        name: "Meeting",
        path: "/meeting",
        exact: true,
        component: () => (
          <AuthGuard
            children={
              <PageLayout>
                <MeetingPage />
              </PageLayout>
            }
          />
        ),
      },
      {
        name: "Meeting",
        path: "/meeting/create",
        exact: true,
        component: () => (
          <AuthGuard
            children={
              <PageLayout>
                <MeetingCreatePage />
              </PageLayout>
            }
          />
        ),
      },
      {
        name: "Tasks",
        path: "/tasks",
        exact: true,
        component: () => (
          <AuthGuard
            children={
              <PageLayout>
                <TasksPage />
              </PageLayout>
            }
          />
        ),
      },
      {
        name: "Details",
        path: "/meeting/:_id",
        component: () => (
          <AuthGuard
            children={
              <PageLayout>
                <MeetingDetailsPage />
              </PageLayout>
            }
          />
        ),
      },
    ],
  },
];
