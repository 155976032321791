import { Badge, Card, List, Result, Skeleton, Space, Tag } from "antd";
import React from "react";
import { Task } from "../../../models/task";

interface Props {
  loading: boolean;
  data: Task[];
  error: any;
}
export const TasksList = (props: Props) => {
  const { loading, data, error } = props;

  if (error) {
    return <Result status="error" title="We couldn't fetch your meetings" />;
  }

  return (
    <Skeleton loading={loading} active>
      <List
        itemLayout="vertical"
        dataSource={data}
        renderItem={(task) => (
          <List.Item style={{ padding: 0 }}>
            <Card
              bodyStyle={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Space>
                  <Badge color="green" />
                  <a href={`/meeting/${task?.meetingId?._id}`}>{task.title}</a>
                </Space>
                <Tag>{task?.status?.name}</Tag>
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </Skeleton>
  );
};
