import { EditOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Button, Form, message } from "antd";
import React, { MouseEvent, useState } from "react";
import { Note } from "../../../models/notes";
import { Participant } from "../../../models/participant";
import { AddMemoForm } from "../forms/add-memo";
import { UPDATE_NOTE_GQL_MUTATION } from "../mutations/note-gql";
import { NOTES_VIEW_GQL_QUERY } from "../queries/notes-gql";

interface Props {
  meetingId: string;
  title: string;
  memo?: Note | null;
  participants: Participant[];
}
export const AddMemoModal = (props: Props) => {
  const { meetingId, title, memo = null, participants } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [updateNote, { loading }] = useMutation<Note>(
    UPDATE_NOTE_GQL_MUTATION,
    {
      refetchQueries: [
        {
          query: NOTES_VIEW_GQL_QUERY,
          variables: { meetingId },
        },
      ],
    }
  );

  const showModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setVisible(true);
  };

  const handleOk = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    form.submit();
  };

  const handleCancel = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: Note & FormData) => {
    updateNote({
      variables: {
        meetingId,
        data: { ...values, meeting: { link: meetingId } },
      },
    })
      .then(() => message.info("Note updated successfully!"))
      .then(() => {
        setVisible(false);
      })
      .catch(() => {
        message.error("Failed to save changes to note");
      });
  };

  return (
    <>
      <Button onClick={showModal}>
        <EditOutlined />
      </Button>
      <Modal
        title={`MEMO: ${title}`}
        visible={visible}
        onOk={handleOk}
        okText="Save memo"
        onCancel={handleCancel}
        confirmLoading={loading}
        width="100vw"
        bodyStyle={{ padding: 0, overflowY: "auto" }}
        maskClosable={false}
      >
        <AddMemoForm
          initialMemo={memo}
          form={form}
          onFinish={onFinish}
          participants={participants}
        />
      </Modal>
    </>
  );
};
