import React from "react";
import { Menu, message } from "antd";
import { useHistory } from "react-router";
import { useRealmApp } from "../../dependencies/realm/use-realm";

export type SelectInfo = {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
};

type Props = {
  displayName: string;
};
export const ProfileDropdown = (props: Props) => {
  const { displayName } = props;

  const app = useRealmApp();
  const history = useHistory();

  const changePasswordClicked = () => {
    app
      ?.sendResetPasswordEmail(app?.currentUser?.profile?.email ?? "")
      .then(() =>
        message.success("Password reset link has been sent to your email")
      )
      .catch(() => message.error("Failed to reset your password"));
  };

  const logoutMenuClicked = () => {
    // apollo?.signOutCurrentUser().finally(() => {
    history.push("/login");
    // });
  };

  return (
    <Menu>
      <Menu.ItemGroup title={displayName} key="display-name">
        <Menu.Item key="settings" disabled={true}>
          Settings
        </Menu.Item>
        <Menu.Item key="change-password" onClick={changePasswordClicked}>
          Change password
        </Menu.Item>
        <Menu.Item key="logout" onClick={logoutMenuClicked}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};
