import { gql } from "@apollo/client";

export const STATUS_LIST_GQL_QUERY = gql`
  query {
    statuses(sortBy: POSITION_ASC) {
      _id
      key
      name
      position
      default
      progress
      deadline
    }
  }
`;
