import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Space, Typography } from "antd";
import { TasksList } from "../components/tasks-list";
import { useFetchMore } from "../../../shared/hooks/fetch-more";
import { NetworkStatus } from "@apollo/client";
import { MY_TASK_LIST_GQL_QUERY } from "../queries/tasks.gql";
import { Task } from "../../../models/task";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

const { Text, Title } = Typography;

export const TasksPage = () => {
  const app = useRealmApp();
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);

  let { loading, error, data, fetchMore, networkStatus } = useFetchMore<{
    tasks: Task[];
  }>(MY_TASK_LIST_GQL_QUERY, {
    variables: {
      userId: app?.currentUser?.id,
    },
  });

  const length = data?.tasks?.length ?? 0;
  const refetching = networkStatus === NetworkStatus.refetch ? true : false;

  useEffect(() => {
    if (length < 30) {
      setCanLoadMore(false);
    } else {
      setCanLoadMore(true);
    }
  }, [data?.tasks, length]);

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        userId: app?.currentUser?.id,
        _id: data?.tasks[data?.tasks.length - 1]?._id,
      },
    })
      .then((response) => {
        response.data.tasks.length === 0
          ? setCanLoadMore(false)
          : setCanLoadMore(true);
      })
      .catch((e) => console.log(e));
  }, [app?.currentUser?.id, data?.tasks, fetchMore]);

  return (
    <div style={{ margin: "auto", width: "100%", maxWidth: "800px" }}>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Title level={3}>
            My <Text className="primary-text-color"> tasks.</Text>
          </Title>
        </Space>
      </Space>
      <Divider />
      <TasksList
        loading={length === 0 && loading}
        error={error}
        data={data?.tasks ?? []}
      />
      <Button
        loading={loading || refetching}
        disabled={!canLoadMore}
        type="dashed"
        style={{ marginTop: 8 }}
        onClick={onLoadMore}
      >
        load more
      </Button>
    </div>
  );
};
