import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Space,
  Typography,
} from "antd";
import { AlertProps } from "antd/lib/alert";
import { useHistory } from "react-router";
import { AuthLayout } from "../layouts/auth-layout";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

const { Link, Title, Text } = Typography;

export const LoginPage = () => {
  const app = useRealmApp();
  const history = useHistory();

  const [alert, setAlert] = useState<AlertProps>({ message: null });

  // useEffect(() => {
  //   app?.signOutCurrentUser();
  // });

  const onFinish = (values: any) => {
    app
      ?.signInWithEmailAndPassword(values.email, values.password)
      .then(() => setAlert({ type: "success", message: "Login successful" }))
      .then(() => history.push("/"))
      .catch(() =>
        setAlert({ type: "error", message: "Something went wrong, try again" })
      );
  };

  return (
    <AuthLayout>
      <Title level={3}>Login</Title>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {alert.message && <Alert {...alert} showIcon />}
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Space
          style={{ display: "flex", justifyContent: "space-between" }}
          direction="horizontal"
        >
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Link href="/reset-password">Forgot password</Link>
          </Form.Item>
        </Space>

        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
      <Divider plain></Divider>
      <Text>
        Don't have an account? <Link href="/signup">Signup here</Link>
      </Text>
    </AuthLayout>
  );
};
