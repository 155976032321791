import React, { ReactChild, useState } from "react";
import { ProfileDropdown } from "../components/profile-dropdown-menu";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Avatar, Dropdown, Layout, Menu, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useRealmApp } from "../../dependencies/realm/use-realm";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

type LayoutProps = {
  children: ReactChild;
};
export const PageLayout = (props: LayoutProps) => {
  const { children } = props;
  const app = useRealmApp();

  const [displayName] = useState<string>(
    app.currentUser?.customData.displayName ?? ""
  );

  return (
    <Layout className="layout">
      <Header className="primary-background-color">
        <Menu
          style={{
            display: "inline-block",
            margin: "auto 2px auto auto",
            float: "right",
          }}
          mode="horizontal"
        >
          <Menu.Item>
            <Dropdown
              overlay={<ProfileDropdown displayName={displayName} />}
              placement="bottomRight"
              arrow
            >
              <Spin
                spinning={false}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              >
                <Avatar>{displayName[0]}</Avatar>
              </Spin>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: 30 }}>
        <Breadcrumbs />
        {children}
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <Text>Meetings</Text> &hearts; ©2020
      </Footer>
    </Layout>
  );
};
