import { gql } from "@apollo/client";

export const MY_TASK_LIST_GQL_QUERY = gql`
  query($userId: [String]) {
    tasks(
      query: { assignees: { _id_in: $userId } }
      limit: 5
      sortBy: _ID_DESC
    ) {
      _id
      title
      meetingId {
        _id
      }
      status {
        name
      }
    }
  }
`;
