import { gql } from "@apollo/client";

export const CREATE_TASK_GQL_MUTATION = gql`
  mutation addTask($data: TaskInsertInput!) {
    insertOneTask(data: $data) {
      title
      status {
        _id
      }
      meetingId {
        _id
      }
    }
  }
`;

export const UPDATE_TASK_GQL_MUTATION = gql`
  mutation updateTask($_id: ObjectId!, $data: TaskUpdateInput!) {
    updateOneTask(query: { _id: $_id }, set: $data) {
      title
      status {
        _id
      }
      assignees {
        _id
        displayName
        email
        phoneNumber
      }
    }
  }
`;
