import {
  DocumentNode,
  QueryHookOptions,
  useQuery,
} from "@apollo/client";

export function useFetchMore<TData = any>(
  Query: DocumentNode,
  options: QueryHookOptions<TData> = {}
) {
  const { loading, error, data, fetchMore, ...others } = useQuery<
    TData
  >(Query, options);
  return { loading, error, data, fetchMore, ...others };
}
