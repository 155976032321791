import React, { useCallback, useEffect, useRef } from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { Participant } from "../../../models/participant";
import "quill/dist/quill.core.css";

import Quill from "quill";
import "quill-mention";

import { Note } from "../../../models/notes";
const Delta = Quill.import("delta");

interface FormProps {
  delta: string;
  text: string;
  html: string;
}

interface Props {
  initialMemo: Note | null;
  form: FormInstance<FormProps>;
  onFinish?: (values: FormData | any) => void;
  participants: Participant[];
}
export type SelectInfo = {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
};

export const AddMemoForm = (props: Props) => {
  const { initialMemo, form, onFinish, participants = [] } = props;
  let quillRef = useRef<HTMLDivElement>(null);
  let memoEditor = useRef<Quill>();

  const filterParticipants = useCallback(
    (searchTerm, renderList, mentionChar) => {
      const list = participants
        .map(({ _id, displayName, ...rest }) => ({
          ...rest,
          id: _id,
          _id,
          value: displayName.toLowerCase(),
        }))
        .filter((participant) => participant.value.includes(searchTerm));
      return renderList(list, searchTerm);
    },
    [participants]
  );

  const onEditorChanged = useCallback(
    (delta: any) => {
      form.setFieldsValue({
        delta: JSON.stringify(delta),
        html: quillRef.current?.querySelector(".ql-editor")?.innerHTML,
        text: memoEditor.current?.getText(),
      });
    },
    [form]
  );

  useEffect(() => {
    if (quillRef.current && !memoEditor.current) {
      memoEditor.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],
            source: filterParticipants,
          },
          // TODO:, Enable tables when we upgrade to V2.0
          // table: true
        },
      });
      if (memoEditor.current) {
        memoEditor.current.on("text-change", onEditorChanged);
        let deltaJSON = initialMemo?.delta ?? "{}";
        try {
          let deltaContent = JSON.parse(deltaJSON);
          memoEditor.current.setContents(new Delta(deltaContent));
        } catch (e) {}
      }
      if (initialMemo) {
        form.setFieldsValue(initialMemo);
      }
    }
    return () => {
      if (memoEditor.current) {
        memoEditor.current.off("text-change", onEditorChanged);
      }
    };
  }, [filterParticipants, form, initialMemo, onEditorChanged, quillRef]);

  const onFormFinish = useCallback(
    (values: FormProps) => {
      if (onFinish) {
        onFinish({
          delta: JSON.stringify(memoEditor.current?.getContents()),
          html: quillRef.current?.querySelector(".ql-editor")?.innerHTML,
          text: memoEditor.current?.getText(),
        });
      }
    },
    [onFinish]
  );

  return (
    <Form form={form} onFinish={onFormFinish}>
      <Form.Item name="delta" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="html" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="text" hidden={true}>
        <Input />
      </Form.Item>
      <div style={{ height: "400px" }}>
        <div ref={quillRef} />
      </div>
    </Form>
  );
};
