import React, { ReactChild, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

type AuthGuardProps = {
  children: ReactChild;
};
export const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;
  const [isAuthed, setIsAuthed] = useState(true);

  const app = useRealmApp();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsAuthed(app.currentUser?.isLoggedIn ?? false);
    }
    return () => {
      isMounted = false;
    };
  }, [app.currentUser]);

  return <>{isAuthed ? children : <Redirect to="/login" />}</>;
};
