import { Form, message, Typography } from "antd";
import React from "react";
import { Meeting } from "../../../models/meeting";
import { useMutation } from "@apollo/client";
import { CreateMeetingForm } from "../forms/create-meeting";
import { useRealmApp } from "../../../dependencies/realm/use-realm";
import { CREATE_MEETING_GQL_MUTATION } from "../mutations/meeting-gql";
import { useHistory } from "react-router";

const { Title } = Typography;

export const MeetingCreatePage = () => {
  const app = useRealmApp();
  const [form] = Form.useForm();

  const history = useHistory();

  const [addMeeting, { error }] = useMutation<{
    insertOneMeeting: Meeting;
  }>(CREATE_MEETING_GQL_MUTATION);

  const onFinish = (values: Meeting & FormData) => {
    values.createdBy = app.currentUser?.id ?? "";
    values.ownerId = app.currentUser?.id ?? "";
    addMeeting({ variables: { data: values } })
      .then(() => {
        form.resetFields();
        message.success("Meeting created");
        history.push(`/meeting`);
      })
      .catch((e) => {
        message.error(error);
        console.log(error);
        console.log(e);
      });
  };

  return (
    <>
      <Title level={3}>New meeting</Title>
      <CreateMeetingForm form={form} onFinish={onFinish} />
    </>
  );
};
