import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import { AlertProps } from "antd/lib/alert";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

const { Link, Title } = Typography;

export const ChangePasswordPage = () => {
  const app = useRealmApp();
  let history = useHistory();

  let location = useLocation();
  const [form] = Form.useForm();

  const { token, tokenId } = queryString.parse(location.search);

  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps>({ message: null });

  const onFinish = (values: any) => {
    setLoading(true);
    app
      ?.resetPassword(values)
      .then(() => {
        setAlert({ type: "success", message: "Password change successful!" });
        form.resetFields();
        history.replace({ pathname: "" });
      })
      .catch(() =>
        setAlert({ type: "error", message: "Something went wrong, try again" })
      )
      .finally(() => setLoading(false));
  };

  if (token === undefined || tokenId === undefined) {
    return null;
  }

  return (
    <Row style={{ marginTop: "80px" }} justify="space-around" align="middle">
      <Col span={8}>
        <Title level={2}>Change password</Title>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ token, tokenId }}
          onFinish={onFinish}
        >
          {alert.message && <Alert {...alert} showIcon />}
          <Form.Item name="token" hidden={true}>
            <Input type="text" />
          </Form.Item>
          <Form.Item name="tokenId" hidden={true}>
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="New password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please provide your new password!",
              },
              { min: 6, message: "Minimum of 6 chars required!" },
              { max: 128, message: "Maximum of 128 chars allowed!" },
            ]}
          >
            <Input.Password type="password" disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirm-password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password type="password" disabled={loading} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Change password
            </Button>
          </Form.Item>
        </Form>

        <Form.Item name="remember">
          <Link href="/login">Go to Login</Link>
        </Form.Item>
      </Col>
    </Row>
  );
};
