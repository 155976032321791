import React, { ReactChildren, ReactElement } from "react";
import * as Realm from "realm-web";
import { UserData } from "../../models/user";
import { RealmAppContext } from "./use-realm";

type Props = {
  appId: string;
  children: ReactElement | ReactChildren;
};
export const RealmAppProvider = ({
  appId,
  children,
}: Props): React.ReactElement => {
  const [app, setApp] = React.useState(new Realm.App(appId));
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);

  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  React.useEffect(() => {
    if (!currentUser) {
      app
        .logIn(Realm.Credentials.anonymous())
        .then(() => setCurrentUser(app.currentUser));
    }
  }, [app, currentUser]);

  const getCustomData = async (): Promise<UserData | undefined> => {
    await currentUser?.refreshCustomData();

    if (currentUser?.providerType === "anon-user") {
      return undefined;
    }

    return currentUser?.customData as UserData;
  };

  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    return app.logIn(credentials).then(() => {
      setCurrentUser(app.currentUser);
    });
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    const registeredUser = await app.emailPasswordAuth.registerUser({
      email,
      password
    });

    // if (!app.currentUser) {
    //   await app.logIn(Realm.Credentials.anonymous());
    // }

    if (app.currentUser?.providerType === "anon-user") {
      return app.currentUser.linkCredentials(credentials);
    }
    return registeredUser;
  };

  const signOutCurrentUser = (): Promise<void> => {
    if (app.currentUser) {
      app.currentUser.logOut();
      return app.removeUser(app.currentUser);
    }
    return Promise.resolve();
  };

  const sendResetPasswordEmail = (email: string): Promise<void> =>
    app.emailPasswordAuth.sendResetPasswordEmail({email});

  const resetPassword = ({
    token,
    tokenId,
    password,
  }: Record<string, string>): Promise<void> =>
    app.emailPasswordAuth.resetPassword({token, tokenId, password});

  const wrapped = {
    ...app,
    currentUser,
    getCustomData,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOutCurrentUser,
    sendResetPasswordEmail,
    resetPassword,
  };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
