import { gql } from "@apollo/client";

export const MEETING_LIST_GQL_QUERY = gql`
  query($today: String) {
    meetings(query: { datetime_lte: $today }, limit: 5, sortBy: _ID_DESC) {
      _id
      createdAt
      title
      datetime
      time
    }
  }
`;
