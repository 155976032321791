import React, { useCallback } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Status } from "../../../models/status";

interface Props {
  data: Status[];
  selected: string;
  onSelect?: (key: React.Key) => void;
}
export type SelectInfo = {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
};

export const StatusDropdown = (props: Props) => {
  const { data, selected, onSelect } = props;

  const onSelectStatus = useCallback((selectedItem) => {
    onSelect && onSelect((selectedItem as SelectInfo).key);
  }, [onSelect]);

  return (
    <Menu onSelect={onSelectStatus}>
      {data.map((status) => (
        <Menu.Item
          key={status._id}
          icon={status.key === selected ? <CheckCircleOutlined /> : null}
        >
          {status.name}
        </Menu.Item>
      ))}
    </Menu>
  );
};
