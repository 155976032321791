import React, { useContext, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import { PageRouteConfig } from "../../types";
import { RouteContext } from "../contexts/route-context";

export const Breadcrumbs = () => {
  const params = useParams<any>();
  const match = useRouteMatch();

  const [crumbs, setCrumbs] = useState<PageRouteConfig[] | undefined>([]);
  const routes = useContext<PageRouteConfig[]>(RouteContext);

  useEffect(() => {
    const appRoutes = routes[0];
    const data = appRoutes.routes
      ?.filter(({ path }) => {
        return match.path.includes(String(path));
      })
      .map(({ matchedPath, ...rest }) => ({
        path: Object.keys(params).length
          ? Object.keys(params).reduce(
              (path, param: string) =>
                String(path)?.replace(`:${param}`, params[param]),
              matchedPath
            )
          : matchedPath,
        ...rest,
      }));
    setCrumbs(data);
  }, [match.path, params, routes]);

  if (crumbs && crumbs.length <= 1) {
    return null;
  }
  return (
    <Breadcrumb style={{ margin: "16px auto", maxWidth: 800 }} separator="•">
      {crumbs?.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <Breadcrumb.Item key={key}>{name}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={key}>
            <Button
              type="link"
              href={path?.toString()}
              style={{ margin: 0, padding: 0 }}
            >
              <ArrowLeftOutlined /> {name}
            </Button>
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};
