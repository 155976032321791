import { gql } from "@apollo/client";

export const UPDATE_NOTE_GQL_MUTATION = gql`
  mutation addTask($meetingId: ObjectId!, $data: NoteInsertInput!) {
    upsertOneNote(query: { meeting: { _id: $meetingId } }, data: $data) {
      html
      delta
      text
      meeting {
        _id
      }
    }
  }
`;
