import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Input,
  Form,
  DatePicker,
  TimePicker,
  Col,
  Row,
  Select,
  Space,
  InputNumber,
  Button,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { formatISO } from "date-fns";
import React, { createElement, useCallback } from "react";
import { Meeting } from "../../../models/meeting";
import { MeetingLinksOptions } from "../utils/select-options/meeting-links";

const { Option } = Select;

export type MeetingForm = { participants: string[] } & Meeting & FormData;

interface Props {
  form: FormInstance;
  onFinish?: (values: FormData | any) => void;
}

export const CreateMeetingForm = (props: Props) => {
  const { form, onFinish } = props;

  const onFormFinish = useCallback(
    (values: MeetingForm) => {
      if (onFinish) {
        const { hours, minutes, ...othervalues } = values;
        const meeting: Meeting = {
          ...othervalues,
          duration: { hours, minutes },
        };

        meeting.datetime = formatISO(new Date(values.datetime?.format()), {
          representation: "date",
        });

        meeting.time = formatISO(new Date(values.time?.format()), {
          representation: "time",
        });
        console.log(meeting);
        onFinish(meeting);
      }
    },
    [onFinish]
  );

  return (
    <Form layout={"vertical"} form={form} onFinish={onFormFinish}>
      <Form.Item name="title" label="Title" rules={[{ required: true }]}>
        <Input id="title" name="title" type="text" />
      </Form.Item>
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item
            name="datetime"
            label="Meeting Date"
            rules={[{ required: true }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="time"
            label="Meeting time"
            rules={[{ required: true }]}
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="duration" label="Duration">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="hours"
                  rules={[{ required: true }]}
                  help="Hours"
                >
                  <InputNumber min={0} max={12} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="minutes"
                  rules={[{ required: true }]}
                  help="Minutes"
                >
                  <InputNumber min={5} max={59} step={5} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Meeting links" rules={[]}>
        <Form.List name="meetinglinks">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space
                  key={field.key}
                  style={{ display: "flex", marginBottom: 4, width: "100%" }}
                  align="baseline"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, "link"]}
                    key={field.key}
                    rules={[{ type: "url", required: true }]}
                  >
                    <Input
                      addonBefore={
                        <Form.Item
                          name={[field.name, "provider"]}
                          key={field.key}
                          noStyle
                        >
                          <Select style={{ width: 180 }}>
                            {Array.from(MeetingLinksOptions.values()).map(
                              (option) => (
                                <Option value={option.key}>
                                  <Space>
                                    {option.icon && createElement(option.icon)}{" "}
                                    {option.name}
                                  </Space>
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add meeting link
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save meeting
        </Button>
      </Form.Item>
    </Form>
  );
};
