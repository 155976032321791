import { Card, List, Result, Skeleton } from "antd";
import React from "react";
import { Meeting } from "../../../models/meeting";

interface Props {
  loading: boolean;
  data: Meeting[];
  error: any;
}
export const MeetingList = (props: Props) => {
  const { loading, data, error } = props;

  if (error) {
    return <Result status="error" title="We couldn't fetch your meetings" />;
  }

  return (
    <Skeleton loading={loading} active>
      <List
        itemLayout="vertical"
        dataSource={data}
        renderItem={(meeting) => (
          <List.Item style={{ padding: 0 }}>
            <Card
              bodyStyle={{
                padding: 16,
              }}
            >
              <Card.Meta
                title={<a href={`/meeting/${meeting._id}`}>{meeting.title}</a>}
                description={meeting.datetime}
              />
            </Card>
          </List.Item>
        )}
      />
    </Skeleton>
  );
};
