import React, { ReactChild } from "react";
import { Layout } from "antd";

const { Content } = Layout;

type LayoutProps = {
  children: ReactChild | ReactChild[];
};
export const BlankLayout = (props: LayoutProps) => {
  const { children } = props;
  return (
    <Layout className="layout">
      <Content style={{ padding: "0 30px" }}>{children}</Content>
    </Layout>
  );
};
