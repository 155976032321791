import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Alert, Button, Divider, Form, Input, Tooltip, Typography } from "antd";
import { AlertProps } from "antd/lib/alert";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useRealmApp } from "../../../dependencies/realm/use-realm";
import { AuthLayout } from "../layouts/auth-layout";
import { CREATE_USER_DATUM_GQL_MUTATION } from "../mutations/auth-gql";

const { Link, Title, Paragraph, Text } = Typography;

export const SignupPage = () => {
  const app = useRealmApp();
  const history = useHistory();

  const [alert, setAlert] = useState<AlertProps>({ message: null });

  const [addUserDatum] = useMutation<any>(CREATE_USER_DATUM_GQL_MUTATION);

  const onFinish = (values: any) => {
    const { username, password, displayName } = values;
    app
      ?.createUserWithEmailAndPassword(username, password)
      .then(() => {
        return app
          ?.signInWithEmailAndPassword(username, password)
          .then((user) => {
            return addUserDatum({
              variables: {
                data: { userId: user.id, displayName, email: username },
              },
            });
          })
          .catch((e) => {});
        //   Realm.handleAuthRedirect();
      })
      .then(() => setAlert({ type: "success", message: "Login successful" }))
      .then(() => history.push("/login"))
      .catch(() =>
        setAlert({ type: "error", message: "Something went wrong, try again" })
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      <Title level={3}>Sign up</Title>
      <Paragraph type="secondary">
        Let's get you all setup, so you can start creating meetings.
      </Paragraph>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {alert.message && <Alert {...alert} showIcon />}
        <Form.Item
          label="Email address"
          name="username"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Display name&nbsp;
              <Tooltip title="What do you want others to call you?">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name="displayName"
          rules={[
            { required: true, message: "Please input your display name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create account
          </Button>
        </Form.Item>
      </Form>
      <Divider plain></Divider>
      <Text>
        Have an account? <Link href="/login">Login here</Link>
      </Text>
    </AuthLayout>
  );
};
