import React from "react";
import { Button, Card, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Task } from "../../../models/task";
import { Status } from "../../../models/status";
import { CREATE_TASK_GQL_MUTATION } from "../mutations/task-gql";
import { useMutation } from "@apollo/client";
import { TASK_LIST_GQL_QUERY } from "../queries/tasks-gql";

interface Props {
  meetingId: string;
  defaultStatus: Status;
}
export type SelectInfo = {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
};

export const CreateTask = (props: Props) => {
  const { meetingId, defaultStatus } = props;
  const [form] = useForm();

  const [addTask, { loading }] = useMutation<Task>(CREATE_TASK_GQL_MUTATION, {
    refetchQueries: [
      {
        query: TASK_LIST_GQL_QUERY,
        variables: { meetingId: meetingId },
      },
    ],
  });

  const onFinish = (values: Task) => {
    let newTask = {
      title: values.title,
      status: { link: values.status },
      meetingId: { link: values.meetingId },
    };
    addTask({ variables: { data: newTask } })
      .then(() => form.resetFields())
      .then(() => message.info("Task created successfully!"))
      .catch(() => {
        message.error("Failed to create task");
      });
  };

  return (
    <Card
      style={{ marginBottom: 16, background: "#f9f9f9" }}
      bodyStyle={{ padding: "12px" }}
    >
      <Form
        layout={"horizontal"}
        initialValues={{ status: defaultStatus._id, meetingId }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="status" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="meetingId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          style={{ marginBottom: 16 }}
          rules={[{ required: true }]}
        >
          <Input.TextArea
            id="title"
            name="title"
            placeholder="Task title"
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create task
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
