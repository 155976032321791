import {
  GoogleOutlined,
  TeamOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

type LinkType = {
  key: string;
  icon: any;
  name: string;
};
const MeetingLinksOptions = new Set<LinkType>();
MeetingLinksOptions.add({
  key: "googlemeet:",
  icon: GoogleOutlined,
  name: "Google meet",
});
MeetingLinksOptions.add({
  key: "zoom:",
  icon: VideoCameraOutlined,
  name: "Zoom",
});
MeetingLinksOptions.add({
  key: "microsoftteams:",
  icon: TeamOutlined,
  name: "Microsoft teams",
});

export { MeetingLinksOptions };
