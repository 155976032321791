import { gql } from "@apollo/client";

export const NOTES_VIEW_GQL_QUERY = gql`
  query($meetingId: ObjectId!) {
    note(query: { meeting: { _id: $meetingId } }) {
      _id
      delta
      html
      text
    }
  }
`;
