import { gql } from "@apollo/client";

export const CREATE_USER_DATUM_GQL_MUTATION = gql`
  mutation addUserDatum($data: UserdatumInsertInput!) {
    insertOneUserdatum(data: $data) {
      email
      displayName
      userId
    }
  }
`;
