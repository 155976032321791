import React, { ReactElement } from "react";
import { renderRoutes } from "react-router-config";
import { PageRouteConfig } from "../../types";

export const RouteContext = React.createContext<PageRouteConfig[]>([]);

type Props = {
  routes: PageRouteConfig[];
};
export const RouteProvider: React.FC<Props> = (props: Props): ReactElement => {
  const { routes } = props;

  return (
    <RouteContext.Provider value={routes}>
      {renderRoutes(routes)}
    </RouteContext.Provider>
  );
};
