import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React, { ReactChildren, ReactElement } from "react";
import { useRealmApp } from "../realm/use-realm";

const createRealmApolloClient = (app: Realm.App) => {
  const link = new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
    fetch: async (uri, options) => {
      if (!app.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      await app.currentUser.refreshCustomData();

      const modifiedOptions = {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${app.currentUser.accessToken}`,
        },
      };
      return fetch(uri, modifiedOptions);
    },
  });

  const cache = new InMemoryCache();

  return new ApolloClient({ link, cache });
};

type Props = {
  children: ReactElement | ReactChildren;
};
export const CustomApolloProvider = ({ children }: Props): ReactElement => {
  const app = useRealmApp();
  const [client, setClient] = React.useState(createRealmApolloClient(app));
  React.useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
