import { Alert, Button, Form, Input, Typography } from "antd";
import { AlertProps } from "antd/lib/alert";
import React, { useState } from "react";
import { useRealmApp } from "../../../dependencies/realm/use-realm";
import { AuthLayout } from "../layouts/auth-layout";

const { Link, Title } = Typography;

export const ResetPasswordPage = () => {
  const app = useRealmApp();

  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps>({ message: null });

  const onFinish = (values: any) => {
    setLoading(true);
    app
      ?.sendResetPasswordEmail(values.email)
      .then(() =>
        setAlert({
          type: "success",
          message:
            "Password reset successful! Check your email for confirmation",
        })
      )
      .catch(() =>
        setAlert({ type: "error", message: "Something went wrong, try again" })
      )
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout>
      <Title level={2}>Reset password</Title>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {alert.message && <Alert {...alert} showIcon />}
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email address!",
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Reset password
          </Button>
        </Form.Item>
      </Form>

      <Form.Item name="remember">
        <Link href="/login">Go to Login</Link>
      </Form.Item>
    </AuthLayout>
  );
};
