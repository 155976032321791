import { useMutation } from "@apollo/client";
import { Modal, Button, Form, message } from "antd";
import React, { useState } from "react";
import { useRealmApp } from "../../../dependencies/realm/use-realm";
import { Meeting } from "../../../models/meeting";
import { CreateMeetingForm } from "../forms/create-meeting";
import { CREATE_MEETING_GQL_MUTATION } from "../mutations/meeting-gql";
import { MEETING_LIST_GQL_QUERY } from "../queries/meeting-gql";

export const CreateMeetingModal = () => {
  const app = useRealmApp();

  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [addMeeting, { error, loading }] = useMutation(
    CREATE_MEETING_GQL_MUTATION,
    {
      update: (cache, { data: { insertOneMeeting } }) => {
        const existingData = cache.readQuery<{ meetings: Meeting[] }>({
          query: MEETING_LIST_GQL_QUERY,
        });
        const existingMeetings = existingData?.meetings ?? [];
        cache.writeQuery({
          query: MEETING_LIST_GQL_QUERY,
          data: { meetings: [insertOneMeeting, ...existingMeetings] },
        });
      },
    }
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: Meeting & FormData) => {
    values.createdBy = app.currentUser?.id ?? "";
    values.ownerId = app.currentUser?.id ?? "";
    addMeeting({ variables: { data: values } })
      .then(() => {
        form.resetFields();
        setVisible(false);
        message.success("Meeting created");
      })
      .catch((e) => {
        // message.error(e);
        console.log(error);
        console.log(e);
      });
  };

  return (
    <>
      <Button onClick={showModal} type="primary">
        Create meeting
      </Button>
      <Modal
        title="New Meeting"
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        okText="Save meeting"
        onCancel={handleCancel}
      >
        <CreateMeetingForm form={form} onFinish={onFinish} />
      </Modal>
    </>
  );
};
