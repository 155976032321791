import React, { MouseEvent } from "react";
import { Button, Empty, Skeleton, Space, Typography } from "antd";
import { Participant } from "../../../models/participant";
import { PrinterOutlined } from "@ant-design/icons";
import { AddMemoModal } from "../modals/add-memo";
import { useQuery } from "@apollo/client";
import { Note } from "../../../models/notes";
import { NOTES_VIEW_GQL_QUERY } from "../queries/notes-gql";

const { Title, Text } = Typography;

interface Props {
  title: string;
  meetingId: string;
  participants: Participant[];
}
export const MeetingNote = (props: Props) => {
  const { title, meetingId, participants } = props;

  const printClicked = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const { loading: notesLoading, data: notesData } = useQuery<{ note: Note }>(
    NOTES_VIEW_GQL_QUERY,
    {
      variables: { meetingId: meetingId },
    }
  );

  const __html = notesData?.note?.html ?? "";

  return (
    <Skeleton loading={notesLoading}>
      <Space
        style={{
          width: "100%",
          paddingTop: 8,
          paddingBottom: 8,
          justifyContent: "space-between",
        }}
      >
        <Title level={5}>Meeting Notes</Title>
        <Button.Group>
          <AddMemoModal
            meetingId={meetingId}
            title={title}
            memo={notesData?.note}
            participants={participants}
          />
          <Button onClick={printClicked}>
            <PrinterOutlined />
          </Button>
        </Button.Group>
      </Space>
      {__html === "" ? (
        <Empty
          description={
            <Text type="secondary"> There are no meeting notes </Text>
          }
        />
      ) : (
        <div
          style={{ backgroundColor: "#fff", padding: 16 }}
          dangerouslySetInnerHTML={{ __html }}
        ></div>
      )}
    </Skeleton>
  );
};
