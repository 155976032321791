import { gql } from "@apollo/client";

export const MEETING_LIST_GQL_QUERY = gql`
  query($_id: ObjectId) {
    meetings(query: { _id_lt: $_id }, limit: 30, sortBy: _ID_DESC) {
      _id
      createdAt
      title
      createdBy
      datetime
      time
      participants {
        _id
        displayName
      }
    }
  }
`;

export const MEETING_DETAILS_GQL_QUERY = gql`
  query($_id: ObjectId!) {
    meeting(query: { _id: $_id }) {
      _id
      createdAt
      createdBy
      datetime
      memo {
        html
        delta
      }
      ownerId
      participants {
        _id
        displayName
        email
        phoneNumber
      }
      time
      title
    }
  }
`;
