import { gql } from "@apollo/client";

export const MY_TASK_LIST_GQL_QUERY = gql`
  query($_id: ObjectId, $userId: [String]) {
    tasks(
      query: { _id_lt: $_id, assignees: { _id_in: $userId } }
      limit: 30
      sortBy: _ID_DESC
    ) {
      _id
      title
      meetingId {
        _id
      }
      status {
        name
      }
    }
  }
`;
