import React, { useState } from "react";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import { TasksList } from "../components/task-list";
import { useFetchMore } from "../../../shared/hooks/fetch-more";
import { MY_TASK_LIST_GQL_QUERY } from "../queries/tasks.gql";
import { Task } from "../../../models/task";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Meeting } from "../../../models/meeting";
import { MeetingList } from "../components/meeting-list";
import { MEETING_LIST_GQL_QUERY } from "../queries/meeting-gql";
import { getGreeting } from "../utils";
import { startOfToday } from "date-fns";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

const { Text, Title, Paragraph } = Typography;

export const DashboardPage = () => {
  const app = useRealmApp();

  let { loading, error, data } = useFetchMore<{ tasks: Task[] }>(
    MY_TASK_LIST_GQL_QUERY,
    {
      variables: {
        userId: [app?.currentUser?.id, app?.currentUser?.customData._id],
      },
    }
  );
  const {
    loading: meetingsLoading,
    error: meetingsError,
    data: meetingsData,
  } = useFetchMore<{ meetings: Meeting[] }>(MEETING_LIST_GQL_QUERY, {
    variables: {
      today: startOfToday().toISOString(),
    },
  });

  const [displayName] = useState<string>(
    app?.currentUser?.customData.displayName
  );

  return (
    <div style={{ margin: "auto", width: "100%", maxWidth: "800px" }}>
      <Title level={2}>
        Good {getGreeting()}{" "}
        <Text className="primary-text-color"> {displayName} 👋</Text>
      </Title>
      <Title level={3}>
        Explore<Text className="primary-text-color"> meetings </Text>and tasks.
      </Title>
      <Button href="/meeting/create" type="primary" size="large">
        Create meeting
      </Button>
      <Divider />
      <Paragraph>Here is a glimpse of what your day looks like</Paragraph>
      <Row gutter={32}>
        <Col span={12}>
          <Title level={5}>Recent meetings</Title>
          <Space style={{ width: "100%" }} direction="vertical" size="large">
            <MeetingList
              loading={meetingsLoading}
              error={meetingsError}
              data={meetingsData?.meetings ?? []}
            />
            <Button href="/meeting" type="default">
              See all meetings <ArrowRightOutlined />
            </Button>
          </Space>
        </Col>
        <Col span={12}>
          <Title level={5}>My tasks</Title>
          <Space style={{ width: "100%" }} direction="vertical" size="large">
            <TasksList
              loading={loading}
              error={error}
              data={data?.tasks ?? []}
            />
            <Button href="/tasks" type="default">
              View all tasks
              <ArrowRightOutlined />
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
