import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Input,
  Space,
  Tag,
  Typography,
} from "antd";
import { MeetingList } from "../components/meetings-list";
import { CreateMeetingModal } from "../modals/create-meeting";
import { MEETING_LIST_GQL_QUERY } from "../queries/meeting-gql";
import { Meeting } from "../../../models/meeting";
import { FilterOutlined } from "@ant-design/icons";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useRealmApp } from "../../../dependencies/realm/use-realm";

const { Text, Title } = Typography;

export const MeetingPage = () => {
  const app = useRealmApp();

  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  let {
    loading,
    error,
    data,
    fetchMore,
    refetch,
    networkStatus,
    client,
  } = useQuery<{
    meetings: Meeting[];
  }>(MEETING_LIST_GQL_QUERY, {
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only',
    // variables: { search: ""},
    // fetchPolicy: "cache-and-network",
  });

  const length = data?.meetings?.length ?? 0;
  const refetching = networkStatus === NetworkStatus.refetch ? true : false;

  useEffect(() => {
    if (length < 30) {
      setCanLoadMore(false);
    } else {
      setCanLoadMore(true);
    }
  }, [length]);

  const onSearch = useCallback(
    (search: string) => {
      if (search === "") {
        refetch({ search });
        return;
      }
      app.currentUser?.functions
        .searchMeetings({ query: search })
        .then((results) => {
          client.writeQuery({
            query: MEETING_LIST_GQL_QUERY,
            data: { meetings: [...JSON.parse(results)] },
            variables: { search },
          });
          setCanLoadMore(false);
        });
    },
    [app.currentUser?.functions, client, refetch]
  );

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        search: undefined,
        _id: data?.meetings[data?.meetings.length - 1]?._id,
      },
    })
      .then((response) => {
        response.data.meetings.length === 0
          ? setCanLoadMore(false)
          : setCanLoadMore(true);
      })
      .catch((e) => console.log(e));
  }, [data?.meetings, fetchMore]);

  return (
    <div style={{ margin: "auto", width: "100%", maxWidth: "800px" }}>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Title level={3}>
            Looking for <Text className="primary-text-color"> meetings.</Text>
          </Title>
          <CreateMeetingModal />
        </Space>
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Input.Search
            size="large"
            style={{ width: "100%", display: "flex", minWidth: "280px" }}
            placeholder="Search meeting"
            allowClear={!loading}
            onSearch={onSearch}
            loading={loading}
          />
          <Button style={{ display: "none" }} size="large" type="link">
            <FilterOutlined />
          </Button>
        </Space>
        <DatePicker
          placeholder="Filter by date"
          style={{ width: "180px", display: "none" }}
        />
      </Space>
      <Divider>
        <Tag color="#dcdcdc">Results: </Tag>
      </Divider>
      <MeetingList
        loading={length === 0 && loading}
        error={error}
        data={data?.meetings ?? []}
      />
      <Button
        loading={loading || refetching}
        disabled={!canLoadMore}
        type="dashed"
        style={{ marginTop: 8 }}
        onClick={onLoadMore}
      >
        load more
      </Button>
    </div>
  );
};
