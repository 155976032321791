import { format } from "date-fns";

const greetings = (type: string): string => {
  const mappedGreetings: { [key: string]: string } = {
    a: "morning",
    n: "afternoon",
    p: "evening",
    mi: "midnight",
  };
  return mappedGreetings[type];
};

export const getGreeting = () => {
  const formatType = format(new Date(), "bbbbb");
  return greetings(formatType);
};
