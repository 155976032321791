import React, { useState } from "react";
import { Button, Popover, Progress } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface Props {
  defaultValue?: number;
  onChange: (value: number) => Promise<void> | void;
}
export type SelectInfo = {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
};

export const ProgressStatus = (props: Props) => {
  const { defaultValue = 0, onChange } = props;
  const [percent, setPercent] = useState<number>(defaultValue);

  const incrementPercent = () => {
    let inc = percent + 10;
    if (inc > 100) {
      inc = 100;
    }
    setPercent(inc);
    onChange(inc);
  };

  const decrementPercent = () => {
    let dec = percent - 10;
    if (dec < 0) {
      dec = 0;
    }
    setPercent(dec);
    onChange(dec);
  };

  return (
    <Popover
      content={
        <Button.Group size="small">
          <Button onClick={decrementPercent} icon={<MinusOutlined />} />
          <Button onClick={incrementPercent} icon={<PlusOutlined />} />
        </Button.Group>
      }
      title="Set progress"
      trigger="click"
    >
      <Progress percent={percent} size="small" />
    </Popover>
  );
};
