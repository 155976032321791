import React, { useCallback, useState } from "react";
import {
  Avatar,
  Divider,
  Select,
  Space,
  Tooltip,
  Typography,
  Form,
  Spin,
  Badge,
} from "antd";
import { useQuery } from "@apollo/client";
import { Participant } from "../../../models/participant";
import { FormInstance } from "antd/lib/form";
import { useDebounce } from "../../../shared/hooks/debounce";
import { UserData } from "../../../models/user";
import { USER_LIST_SEARCH_GQL_QUERY } from "../queries/users-gql";
import { LabeledValue } from "antd/lib/select";
import { ObjectId } from "bson";
import { SendOutlined } from "@ant-design/icons";
import { useValidation } from "react-class-validator";
import { AddParticipantValidation } from "../utils/validators/add-participant-validator";

const { Option } = Select;
const { Text } = Typography;

interface Props {
  form?: FormInstance;
  onFinish?: (values: FormData | any) => void;
  participants?: Participant[];
}
export const AddParticipantsForm = (props: Props) => {
  const { form, participants = [], onFinish } = props;

  var participantsObject = participants.reduce<{ [key: string]: Participant }>(
    (obj, item) => {
      obj[item._id] = item;
      return obj;
    },
    {}
  );

  const [selectedParticipants, setSelectedParticipants] = useState(
    participantsObject
  );

  const [userSearchTerm, setUserSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(userSearchTerm, 100);

  const [validate, errors] = useValidation(AddParticipantValidation);

  const { loading: searchLoading, data: searchData } = useQuery<{
    userdata: UserData[];
  }>(USER_LIST_SEARCH_GQL_QUERY, {
    variables: { displayName: debouncedSearchTerm },
  });

  const onSearchUsers = async (search: string) => {
    if (await validate({ search })) {
      setUserSearchTerm(search);
    }
  };

  const onSelectChange = useCallback(
    (values: LabeledValue[]) => {
      values.forEach((val) => {
        const { key, value } = val;
        const cacheUser = searchData?.userdata.find(
          (x) => x.email === val.value
        );
        if (cacheUser) {
          const { userId, displayName, email, phoneNumber } = cacheUser;
          setSelectedParticipants({
            ...selectedParticipants,
            [key ?? userId]: { _id: userId, displayName, email, phoneNumber },
          });
        } else {
          const uniqueId = new ObjectId().toHexString();
          setSelectedParticipants({
            ...selectedParticipants,
            [key ?? uniqueId]: {
              _id: uniqueId,
              displayName: value.toString(),
              email: value.toString(),
            },
          });
        }
      });
    },
    [searchData?.userdata, selectedParticipants]
  );

  const onDeselect = useCallback(
    (value: LabeledValue) => {
      const participants = { ...selectedParticipants };
      const user = Object.values(participants).find(
        (x) => x.email === value.key
      );
      if (user) {
        delete participants[user._id];
      }
      console.log(participants);
      setSelectedParticipants(participants);
    },
    [selectedParticipants]
  );

  const onFormFinish = useCallback(
    (values: any) => {
      if (onFinish) {
        onFinish(Object.values(selectedParticipants));
      }
    },
    [onFinish, selectedParticipants]
  );

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={{
          participants: [
            ...Object.values(selectedParticipants).map((p) => ({
              label: p.email,
              key: p._id,
              value: p.email,
            })),
          ],
        }}
      >
        <Form.Item
          name="participants"
          label="Search by email"
          help="You can invite guest users by email too."
          validateTrigger={["onBlur", "onChange"]}
        >
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            placeholder="Search participants"
            labelInValue={true}
            onChange={onSelectChange}
            onSearch={onSearchUsers}
            onDeselect={onDeselect}
            filterOption={false}
            loading={searchLoading}
            notFoundContent={searchLoading ? <Spin size="small" /> : null}
            showSearch
            autoFocus
          >
            {errors.search && (
              <Option disabled value="">
                Please provide a valid email
              </Option>
            )}
            {searchData?.userdata.map((participant) => (
              <Option key={participant._id} value={participant.email}>
                {participant.email}
              </Option>
            ))}
            {userSearchTerm.length > 0 &&
              searchData?.userdata.length === 0 &&
              (() => {
                const uniqueId = new ObjectId().toHexString();
                return (
                  <Option key={uniqueId} value={userSearchTerm}>
                    <Space>
                      <Text type="secondary">
                        <SendOutlined rotate={-30} /> <small>Invite</small>
                      </Text>
                      <Text>{userSearchTerm}</Text>
                    </Space>
                  </Option>
                );
              })()}
          </Select>
        </Form.Item>
      </Form>
      <Divider />
      <Space direction="vertical">
        <Text type="secondary" strong>
          Preview <Badge count={selectedParticipants.length} />
        </Text>
        <Avatar.Group
          size="small"
          maxCount={5}
          maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
        >
          {Object.values(selectedParticipants)?.map((participant) => (
            <Tooltip
              key={participant._id}
              title={participant.displayName}
              placement="top"
            >
              <Avatar size={32} style={{ backgroundColor: "#f56a00" }}>
                {participant.displayName &&
                  participant.displayName[0].toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      </Space>
    </div>
  );
};
