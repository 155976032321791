import React from "react";
import { RealmAppProps } from "./types";

export const RealmAppContext = React.createContext<RealmAppProps | any>({});
export const useRealmApp = (): RealmAppProps => {
  const app = React.useContext<RealmAppProps>(RealmAppContext);

  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};
