import { Avatar, Skeleton, Space, Tabs, Tooltip, Typography } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { format, formatRelative, parseISO } from "date-fns";
import { MeetingBoard } from "../components/meeting-board";
import { Meeting } from "../../../models/meeting";
import { MeetingNote } from "../components/meeting-note";
import { useQuery } from "@apollo/client";
import { MEETING_DETAILS_GQL_QUERY } from "../queries/meeting-gql";
import { AddParticipantModal } from "../modals/add-participants";

const { Text, Title } = Typography;
const { TabPane } = Tabs;

type ParamsProp = {
  _id: string;
};

export const MeetingDetailsPage = () => {
  let { _id } = useParams<ParamsProp>();
  const { loading, data } = useQuery<{ meeting: Meeting }>(
    MEETING_DETAILS_GQL_QUERY,
    {
      variables: { _id },
    }
  );
  console.log(data?.meeting);

  return (
    <>
      <div style={{ margin: "auto", maxWidth: 800 }}>
        <Skeleton loading={loading}>
          <Space direction="vertical">
            <Text type="secondary">
              <Space>
                <CalendarOutlined />
                {data &&
                  format(parseISO(data?.meeting.datetime), "d MMMM, yyyy")}
              </Space>
            </Text>
          </Space>
          <Title level={3}>{data?.meeting.title}</Title>
          <Space direction="vertical">
            {data?.meeting.participants && (
              <Avatar.Group
                maxCount={2}
                size="default"
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {data?.meeting.participants?.map((participant) => (
                  <Tooltip title={participant.displayName} placement="top">
                    <Avatar style={{ backgroundColor: "#f56a00" }}>
                      {participant.displayName[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              </Avatar.Group>
            )}
            <AddParticipantModal
              meetingId={_id}
              participants={data?.meeting.participants ?? []}
            />
            <Text type="secondary">
              <small>
                <Space>
                  <ClockCircleOutlined />
                  Created:{" "}
                  {data?.meeting.createdAt &&
                    formatRelative(
                      parseISO(data?.meeting.createdAt ?? new Date()),
                      new Date()
                    )}
                </Space>
              </small>
            </Text>
          </Space>
        </Skeleton>
      </div>
      <Skeleton loading={loading}>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Meeting notes" key="meeting-notes">
            <MeetingNote
              title={data?.meeting.title ?? ""}
              meetingId={data?.meeting._id ?? ""}
              participants={data?.meeting.participants ?? []}
            />
          </TabPane>
          <TabPane tab="Action tracker" key="meeting-board">
            <MeetingBoard
              meetingId={data?.meeting._id ?? ""}
              participants={data?.meeting.participants ?? []}
            />
          </TabPane>
        </Tabs>
      </Skeleton>
    </>
  );
};
