import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  List,
  Result,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { format, formatRelative } from "date-fns";
import React from "react";
import { Meeting } from "../../../models/meeting";

const { Text } = Typography;

interface Props {
  loading: boolean;
  data: Meeting[];
  error: any;
}
export const MeetingList = (props: Props) => {
  const { loading, data, error } = props;

  if (error) {
    return <Result status="error" title="We couldn't fetch your meetings" />;
  }

  return (
    <Skeleton loading={loading} active>
      <List
        itemLayout="vertical"
        dataSource={data}
        renderItem={(meeting) => (
          <List.Item style={{ padding: 0 }}>
            <Card bodyStyle={{ padding: 16 }}>
              <Space direction="vertical">
                <Text type="secondary">
                  <Space>
                    <CalendarOutlined />
                    {format(new Date(meeting.datetime), "d MMMM, yyyy")}
                  </Space>
                </Text>
              </Space>
              <List.Item.Meta
                title={<a href={`/meeting/${meeting._id}`}>{meeting.title}</a>}
              />
              <Space direction="vertical">
                {meeting.participants?.length > 0 && (
                  <Avatar.Group
                    maxCount={2}
                    size="default"
                    maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {meeting.participants?.map((participant) => (
                      <Tooltip
                        key={participant._id}
                        title={participant.displayName}
                        placement="top"
                      >
                        <Avatar
                          key={participant._id}
                          style={{ backgroundColor: "#f56a00" }}
                        >
                          {participant.displayName[0].toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                )}
                <Text type="secondary">
                  <small>
                    <ClockCircleOutlined />{" "}
                    {formatRelative(new Date(meeting.createdAt), new Date())}
                  </small>
                </Text>
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </Skeleton>
  );
};
