import React, { useCallback, useEffect, useState } from "react";
import {
  BellOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  message,
  Popover,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { StatusDropdown } from "./status-dropdown";
import { LabeledValue } from "antd/lib/select";
import { Task } from "../../../models/task";
import { Status } from "../../../models/status";
import { Participant } from "../../../models/participant";
import { ProgressStatus } from "./progress-status";
import { DEFAULT_DATE_FORMAT } from "../../../config/constants";
import moment from "moment";
import { TASK_LIST_GQL_QUERY } from "../queries/tasks-gql";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_GQL_MUTATION } from "../mutations/task-gql";
import { formatISO } from "date-fns";

const { Paragraph, Text } = Typography;
const { Option } = Select;

interface Props {
  meetingId: string;
  data: Task;
  statuses: Status[];
  progress?: boolean;
  participants: Participant[];
}

export const TaskCard = (props: Props) => {
  const { data, meetingId, participants, statuses, progress } = props;

  const [assignees, setAssignees] = useState<LabeledValue[]>([]);

  const [updateTask, { loading }] = useMutation<Task>(
    UPDATE_TASK_GQL_MUTATION,
    {
      refetchQueries: [
        {
          query: TASK_LIST_GQL_QUERY,
          variables: { meetingId: meetingId },
        },
      ],
    }
  );

  useEffect(() => {
    if (data.assignees) {
      const defaultAssignees = data.assignees.map(({ _id, displayName }) => ({
        key: _id,
        label: displayName,
        value: _id,
      }));
      setAssignees(defaultAssignees);
    }
  }, [data.assignees]);

  const onSelectStatus = useCallback(
    (selectedItem: React.Key) => {
      const taskUpdateData = { status: { link: selectedItem } };
      updateTask({ variables: { _id: data._id, data: taskUpdateData } })
        .then(() => {
          message.success("Task updated successfully");
        })
        .catch(() => {
          message.error("Failed to update task");
        });
    },
    [data._id, updateTask]
  );

  const onSelectAssignee = async (values: LabeledValue[]) => {
    const assignedParticipants = values.map((val) => {
      const { __typename, ...otherAttr } =
        participants.find((participant) => participant._id === val.key) ?? {};
      return otherAttr;
    });
    const taskUpdateData = { assignees: assignedParticipants };
    updateTask({ variables: { _id: data._id, data: taskUpdateData } })
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch(() => {
        message.error("Failed to update task");
      });
    setAssignees(values);
  };

  const onSetProgress = async (values: number) => {
    const taskUpdateData = { progress: values };
    updateTask({ variables: { _id: data._id, data: taskUpdateData } })
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch(() => {
        message.error("Failed to update task");
      });
  };

  const disabledDate = (current: any) =>
    current && current < moment().endOf("day");

  const onSetDeadline = async (_: any, dateString: string | string[]) => {
    const taskUpdateData = {
      deadline: _ ? formatISO(new Date(_.format())) : new Date(0),
    };
    updateTask({ variables: { _id: data._id, data: taskUpdateData } })
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch(() => {
        message.error("Failed to update task");
      });
  };

  const onTitleEdited = async (value: string) => {
    const taskUpdateData = { title: value };
    updateTask({ variables: { _id: data._id, data: taskUpdateData } })
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch(() => {
        message.error("Failed to update task");
      });
  };

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Card style={{ maxWidth: 300 }} bodyStyle={{ padding: "12px" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Paragraph editable={{ onChange: onTitleEdited }}>
            {data.title}
          </Paragraph>
          {progress && (
            <ProgressStatus
              defaultValue={data?.progress ?? 0}
              onChange={onSetProgress}
            />
          )}
        </Space>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 16,
            alignItems: "center",
          }}
        >
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Space style={{ alignItems: "flex-start" }}>
              <Avatar.Group
                maxCount={2}
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {data.assignees?.map((participant) => (
                  <Tooltip
                    key={participant._id}
                    title={participant.displayName}
                    placement="top"
                  >
                    <Avatar size={32} style={{ backgroundColor: "#f56a00" }}>
                      {participant.displayName && participant.displayName[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              </Avatar.Group>
              <Popover
                placement="topRight"
                content={
                  <Select
                    mode="multiple"
                    labelInValue
                    value={assignees}
                    placeholder="Assign users"
                    filterOption={false}
                    onChange={onSelectAssignee}
                    style={{ width: "100%" }}
                  >
                    {participants?.map((participant) => (
                      <Option
                        key={participant._id}
                        value={participant._id || participant.displayName}
                      >
                        {participant.displayName}
                      </Option>
                    ))}
                  </Select>
                }
                title="Assign participants"
                trigger="click"
              >
                <Button type="link" shape="circle" icon={<PlusOutlined />} />
              </Popover>
            </Space>
          </Space>
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            overlay={
              <StatusDropdown
                data={statuses}
                selected={data.status._id}
                onSelect={onSelectStatus}
              />
            }
          >
            <Button type="text" style={{ padding: "2px", marginLeft: "auto" }}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
        {progress && (
          <Space style={{ marginTop: 8 }}>
            <Text type="secondary">Deadline:</Text>
            <DatePicker
              defaultValue={data?.deadline ? moment(data?.deadline) : undefined}
              disabledDate={disabledDate}
              size="small"
              format={DEFAULT_DATE_FORMAT}
              onChange={onSetDeadline}
              inputReadOnly={true}
              suffixIcon={<BellOutlined />}
              placeholder="Set deadline"
              bordered={false}
            />
          </Space>
        )}
      </Card>
    </Spin>
  );
};
